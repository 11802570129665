@import 'overwrite.css';

:root {
  --content-width: 1280px;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: Ubuntu;
  src: local('Ubuntu');
  src: url(https://s91588.cdn.ngenix.net/fonts/ubuntu-bold.eot);
  src:
    url(https://s91588.cdn.ngenix.net/fonts/ubuntu-bold.eot#iefix) format('embedded-opentype'),
    url(https://s91588.cdn.ngenix.net/fonts/ubuntu-bold.woff) format('woff'),
    url(https://s91588.cdn.ngenix.net/fonts/ubuntu-bold.ttf) format('truetype'),
    url(https://s91588.cdn.ngenix.net/fonts/ubuntu-bold.svg#ubuntu-bold) format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

.bold {
  font-weight: 700;
}
